/* Helper -- assorted helper methods */
(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define([], factory);
    } else if (typeof module === 'object' && module.exports) {
        // Node. Does not work with strict CommonJS, but
        // only CommonJS-like environments that support module.exports,
        // like Node.
        module.exports = factory();
    } else {
        // Browser globals (root is window)
        root.returnExports = factory();
    }
}(typeof self !== 'undefined' ? self : this, function () {

    var module = {};

    module.checkboxConditionallyHideDependent = function() {
        var checkbox = $('#' + $('#injected_form_data').data('checkbox-id'));
        var checkboxDependent = $('#' + $('#injected_form_data').data('checkbox-dependent-id'));
        module.updateCheckboxDependent(checkbox, checkboxDependent);
        checkbox.change(function() {
            module.updateCheckboxDependent($(this), checkboxDependent)
        });
    };

    module.updateCheckboxDependent = function(checkbox, dependentElement) {
        if(checkbox.is(':checked')) {
            dependentElement.show();
        }
        else {
            dependentElement.hide();
        }
    };

    module.activateDatePickers = function() {
        $('.datePicker').datepicker({ dateFormat: "yy-mm-dd" });
    };

    module.addTableDateParser = function() {
      $.tablesorter.addParser({
        id: 'dates',
        is: function(s) {
          // return false so this parser is not auto detected
          return false;
        },
        format: function(dateString) {
          // Assuming YYYY/mm/dd
          var dateComponents = dateString.split("/");
          var theDate = new Date(dateComponents[0], (dateComponents[1]-1), dateComponents[2]);
          return theDate.getTime();
        },
        type: 'numeric'
      });
    };

    return module;
}));