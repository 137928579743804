/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// see https://github.com/webpack/webpack/issues/4258
jQuery = require('jquery');
window.jQuery = jQuery;
window.$ = jQuery;

require('jquery-ui/ui/widgets/datepicker');
require('tablesorter');
require('bootstrap-sass');
require('../javascripts/bootstrap3-typeahead');

Confirm = require('../javascripts/confirm.js');
Helper = require('../javascripts/helper.js');
