/* Confirm -- converts an anchor tag into a form PUT, POST or DELETE with a confirmation dialog */
(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define([], factory);
    } else if (typeof module === 'object' && module.exports) {
        // Node. Does not work with strict CommonJS, but
        // only CommonJS-like environments that support module.exports,
        // like Node.
        module.exports = factory();
    } else {
        // Browser globals (root is window)
        root.returnExports = factory();
    }
}(typeof self !== 'undefined' ? self : this, function () {

    var module = {};

    function method(element, methodName)
    {
        var form = document.createElement('form');
        form.style.display = 'none';
        element.parentNode.appendChild(form);
        form.method = 'POST';
        form.action = element.href;

        var method = document.createElement('input');
        method.setAttribute('type', 'hidden');
        method.setAttribute('name', '_method');
        method.setAttribute('value', methodName);
        form.appendChild(method);

        var csrf = document.createElement('input');
        csrf.setAttribute('type', 'hidden');
        csrf.setAttribute('name', 'authenticity_token');
        csrf.setAttribute('value', $("meta[name='csrf-token']").attr('content'));
        form.appendChild(csrf);

        form.submit();
        return false;
    }

    module.confirm = function (element, message) {
        if (!message) {
            message = "Are you sure?";
        }
        if (confirm(message)) {
            return module.put(element);
        }
        return false;
    };

    module.put = function(element) {
        return method(element, "put")
    };

    module.post = function(element) {
        return method(element, "post")
    };

    module.delete = function (element, message) {
        if (!message) {
            message = "Are you sure?";
        }
        if (confirm(message)) {
            return method(element, "delete");
        }
        return false;
    };
    return module;
}));
